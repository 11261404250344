import React from "react"

const Feedback = () => (
    <div>
        <p className="feedback">Feedback 💌 nlsu.fun@protonmail.com</p>
        <p className="feedback">Copyright	&#169; 2023 NLSU. All rights reserved</p>
    </div>

)
//heisenbergposition@protonmail.com
export default Feedback
